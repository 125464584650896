<template>
  <el-dialog
    custom-class="preview-name-dialog common-dialog"
    :visible="visible"
    title="命名预览"
    @close="handleClose"
  >
    <div class="text-a-c f-14 mb-10 color-26">
      <span class="mr-16"
        >所有的变量都是根据你的投放设置动态生成的命名规则</span
      >
      <el-popover placement="bottom" width="457" trigger="click">
        <div v-html="tip"></div>
        <el-button slot="reference" type="text">点击查看</el-button>
      </el-popover>
    </div>
    <div>
      <el-row :gutter="16" v-for="(item, index) in data" :key="index">
        <el-col
          :span="6"
          :offset="1"
          class="text-a-r mb-16"
          >{{ item.title }}</el-col
        >
        <el-col :span="16" class="color-26">{{ item.val }}</el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    tip: {
      type: String,
      default: () => "",
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.preview-name-dialog {
  width: 650px;
  .el-dialog__header {
    @extend .dp-flex;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  .el-dialog__body {
    background-color: rgb(245, 245, 247);
  }
  ul {
    margin: 60px 0 108px;
  }
}
</style>