<template>
  <el-drawer
    @close="closeDrawer"
    custom-class="preview-drawer"
    size="700px"
    title="广告帐号设置"
    :visible="visible"
  >
    <div v-if="multipleSelection.length" class="header dp-flex align-item-c">
      <el-button class="mr-10" type="text"
        >已选{{ multipleSelection.length }}个</el-button
      >
      <el-button
        :disabled="tableData.length == 1"
        @click="handleDelAccount(multipleSelection)"
        class="f-14"
        type="text"
        icon="el-icon-delete"
      ></el-button>
    </div>
    <cus-table
      ref="cusTableRef"
      :previewAllData="previewAllData"
      :tableParams="tableParams"
      :tableHeightData="tableHeightData"
      :tableData="tableData"
      :colData="colData"
      :loading="loading"
      @select-change="handleSelectionChange"
      @expand-change="hanleExpandChange"
    >
      <template v-slot:expandslot="{ scope, data }">
        <ul>
          <li class="mb-10 dp-flex" v-for="(item, index) in data" :key="index">
            <span
              class="align-self-start"
              :style="
                `width:${
                  $route.query.mark == 'name'
                    ? '160px'
                    : $route.query.mark == 'trace'
                    ? '200px'
                    : '230px'
                };`
              "
              >{{ item.prop ? item.label + "原值" : item.label }}：</span
            >
            <span v-if="item.prop">{{
              item.prop == "fb_pixel" && scope.row[item.prop].id==null
                ? "无"
                : scope.row[item.prop]
            }}</span>
            <span v-else>{{
              scope.row["split_type"] == index ? "是" : "否"
            }}</span>
          </li>
        </ul>
      </template>
    </cus-table>
  </el-drawer>
</template>

<script>
import cusTable from "@/views/components/cusTable";
export default {
  components: {
    cusTable
  },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    previewAllData: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableParams: {
        className: "",
        border: true,
        selection: true,
        isTableHeight: true,
        expandBeforeMul: true
      },
      tableHeightData: [
        {
          selector: ".preview-drawer .el-drawer__title",
          cssProperty: "height"
        },
        {
          selector: ".preview-drawer .el-drawer__body",
          cssProperty: "paddingTop"
        },
        {
          selector: ".preview-drawer .el-drawer__body",
          cssProperty: "paddingBottom"
        },
        {
          selector: ".preview-drawer .el-drawer__body .header",
          cssProperty: "height"
        }
      ],
      colData: [
        { label: "广告帐号名称", prop: "account_name" },
        {
          label: "广告帐户ID",
          prop: "account_no"
        }
      ],
      multipleSelection: [],
      loading: false,
      expands: []
    };
  },
  methods: {
    closeDrawer() {
      this.$refs.cusTableRef.$refs.tableRef.clearSelection();
      this.$emit("close-drawer");
      this.expands = [];
    },
    // 表格多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    hanleExpandChange(obj, arr) {
      this.expands = arr;
    },
    // 删除
    handleDelAccount(multipleSelection) {
      // console.log("handleDelAccount", multipleSelection);
      // 至少保留一条数据
      if (multipleSelection.length == this.tableData.length) {
        this.$message("请至少保留一条数据！");
        // 清空用户选项
        this.$refs.cusTableRef.$refs.tableRef.clearSelection();
      } else {
        let arr = this.tableData
          .filter(item => multipleSelection.every(m => m.id != item.id))
          .map(i => i.id);
        // console.log("删除", arr);
        this.$emit("del-account", arr);
      }
    }
  },
  watch: {
    // 弹窗关闭时所有行关闭
    expands(val) {
      if (!val.length) {
        this.tableData.forEach(item => {
          this.$refs.cusTableRef.$refs.tableRef.toggleRowExpansion(item, false);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.preview-drawer {
  ::v-deep :focus {
    outline: 0;
  }
  .el-drawer__header {
    margin-bottom: 0;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }
  .el-drawer__body {
    padding: 24px;
    overflow: auto;
  }
  .header {
    height: 30px;
  }
  .el-table .el-icon-arrow-right:before {
    content: "\e791";
  }
  .el-table__expanded-cell {
    background-color: #fbfbfb;
    padding: 16px 30px 16px 100px;
  }
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>