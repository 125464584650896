<template>
  <el-dialog
    custom-class="url-trace-dialog common-dialog"
    :visible="visible"
    title="设置Google Analytics跟踪"
    @close="handleClose"
  >
    <span slot="title"
      >设置Google Analytics跟踪
      <el-popover placement="bottom" width="500" trigger="hover">
        <div
          v-html="
            `<p>来源：用来识别访问量来源，例如：Facebook、Instagram、搜索引擎或其他来源</p><br/><p>媒介：识别广告媒介，例如：banner、email、Facebook_Feed或Instagram_Story</p><br/><p>名称：识别特定推广或策略性营销活动，例如：summer_sale</p><br/><p>关键字：自定义追踪标签</p><br/><p>内容：用来区分指向相同网址的广告或链接，例如：white_logo、black_logo</p>`
          "
        ></div>
        <i
          slot="reference"
          class="el-icon-warning-outline color-theme cursor-pointer"
        ></i>
      </el-popover>
    </span>
    <el-form
      class="cus-form com-form"
      label-position="top"
      ref="formRef"
      :model="formModel"
      :rules="formRules"
    >
      <el-form-item
        v-for="(item, index) in cusRuleSetData"
        :key="index"
        :label="item.label"
        :prop="item.prop"
      >
        <el-select
          @change="val => handleChangeAdRule(val, { index })"
          allow-create
          filterable
          v-model="formModel[item.prop]"
          multiple
          :placeholder="
            item.prop != 'from' ? `请选择${item.label}` : '请选择来源平台'
          "
          :disabled="item.disabled"
        >
          <el-option
            v-for="(opt, i) in item.options"
            :key="i"
            :label="opt"
            :value="opt"
          >
          </el-option>
        </el-select>
        <!-- 连接符 -->
        <el-select
          :disabled="item.disabled"
          size="small"
          class="small-select"
          v-model="formModel[item.prop + '_con']"
        >
          <el-option
            v-for="(opt, i) in connectors"
            :key="i"
            :label="opt.label"
            :value="opt.value"
          >
          </el-option>
        </el-select>
        <!-- 推荐词 -->
        <div class="recommend-wrap dp-flex">
          <el-button
            @click="handleSelectRecommend(item.prop, index, ritem, rindex)"
            v-for="(ritem, rindex) in item.recommends"
            :key="rindex"
            type="text"
            size="small"
            :disabled="item.disabled"
          >
            {{ ritem }}
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    connectors: {
      type: Array,
      default: () => []
    },
    initData: {
      type: Object,
      default: () => {}
    },
    selDatas: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formModel: {
        // 模板变量原型调整-增加广告系列ID/优化师ID/来源平台
        utm_content: "广告系列ID", //广告系列ID
        utm_content_con: "_",
        opt_id: "当前优化师ID", //优化师ID
        opt_id_con: "_",
        from: "facebook", //来源平台
        from_con: "_",

        utm_source: "",
        utm_source_con: "_",
        utm_medium: "",
        utm_medium_con: "_",
        utm_campaign: "",
        utm_campaign_con: "_",
        utm_term: "",
        utm_term_con: "_"
      },
      formRules: {},
      cusRuleSetData: []
    };
  },
  methods: {
    // 创建cusRuleSetData
    createCusData(selDatas) {
      // console.log("selDatas", selDatas);
      let arr = [],
        arr1 = [
          "创建日期（年月日）",
          "营销目标缩写",
          "国家中文",
          "国家代码",
          "性别缩写",
          "创意类型缩写",
          "受众类型缩写"
        ];
      // console.log("this.tplVars", this.tplVars);
      let n = this.tplVars().length;
      // 模板变量原型调整-增加广告系列ID/优化师ID/来源平台-放最前面
      this.tplVars().forEach((k, i) => {
        let obj = {
          label:
            i > 2 ? k.split("_").join(" ") + "(来源)" : k.split("_").join(" "),
          prop: k,
          disabled: i < 3 ? true : false,
          options: i > 2 ? selDatas : [],
          recommends: i > 2 ? JSON.parse(JSON.stringify(arr1)) : [],
          recommendsCopy: i > 2 ? JSON.parse(JSON.stringify(arr1)) : []
        };
        arr.push(obj);
      });

      this.cusRuleSetData = [...arr];
      // console.log("this.cusRuleSetData", this.cusRuleSetData);
    },
    // 模板变量
    tplVars() {
      let props = Object.keys(this.formModel),
        arr = props.filter(item => {
          return item.slice(-4) != "_con";
        });
      return arr;
    },
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      // 用户选定数据回显到网址追踪的textarea中
      // console.log("this.formModel", this.formModel);
      let props = Object.keys(this.formModel),
        arr1 = props.filter(item => item.slice(-4) != "_con"),
        str = "";
      // console.log("arr1", arr1);
      arr1.forEach((item, i) => {
        // console.log("this.formModel[item]", this.formModel[item]);
        if (this.formModel[item][0]) {
          let arr = this.formModel[item].map(item => {
            if (this.selDatas.every(s => s != item) && i < arr1.length - 3) {
              // console.log("t1");
              return item;
            } else {
              // console.log("t2");
              return `{${item}}`;
            }
          });
          str += item + "=" + arr.join(this.formModel[item + "_con"]) + "&";
        }
      });
      // console.log("str", str);
      this.$emit("create-url-trace", str.slice(0, -1));
    },
    // 下拉框改变
    handleChangeAdRule(val, { index }) {
      let arr = val.filter(item => {
        return this.cusRuleSetData[index].recommendsCopy.some(r => r == item);
      });
      if (arr.length) {
        // 输入框中有推荐词
        arr.forEach(item => {
          let _index = this.cusRuleSetData[index].recommends.findIndex(
            r => r == item
          );
          if (_index != -1) {
            this.cusRuleSetData[index].recommends.splice(_index, 1);
          } else {
            this.cusRuleSetData[index].recommends = this.cusRuleSetData[
              index
            ].recommendsCopy.filter(r => r != item);
          }
        });
      } else {
        // 输入框中无推荐词
        this.cusRuleSetData[index].recommends = JSON.parse(
          JSON.stringify(this.cusRuleSetData[index].recommendsCopy)
        );
      }
      // console.log("change-end");
    },
    // 选中推荐词
    handleSelectRecommend(prop, index, ritem, rindex) {
      let _index = this.formModel[prop].findIndex(item => item == ritem);
      if (_index == -1) {
        this.formModel[prop].push(ritem);
        this.cusRuleSetData[index].recommends.splice(rindex, 1);
      }
    }
  },
  created() {},
  mounted() {},
  watch: {
    selDatas: {
      handler(val) {
        this.createCusData(val);
      },
      deep: true
    },
    initData: {
      handler(val) {
        if (val != {}) {
          let arr = ["utm_content", "opt_id", "from"];
          // 初始数据是否是推荐词
          let props = Object.keys(this.initData);
          props.forEach(p => {
            if (arr.includes(p) && !this.initData[p]) {
              this.formModel[p] = this.formModel[p]; //接口没有网址初始数据时，采用前端设置的初始数据
            } else {
              this.formModel[p] = this.initData[p];
            }
          });
          this.cusRuleSetData.forEach(item => {
            let arr = [];
            if (this.formModel[item.prop]) {
              arr = this.formModel[item.prop].filter(p => {
                return item.recommendsCopy.some(r => r == p);
              });
            }
            if (arr.length) {
              arr.forEach(a => {
                let i = item.recommends.findIndex(j => j == a);
                if (i != -1) {
                  item.recommends.splice(i, 1);
                }
              });
            }
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.url-trace-dialog {
  width: 800px;
  .el-dialog__header {
    @extend .dp-flex;
    @extend .justify-c;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  .cus-form {
    .el-select {
      width: 70%;
    }
    .small-select {
      width: auto;
      .el-input {
        width: 32px;
      }
      .el-input__suffix {
        @extend .dp-none;
      }
      .el-select-dropdown__item {
        @extend .f-12;
      }
    }
    .recommend-wrap {
      // li {
      //   line-height: initial;
      //   padding: 2px;
      //   color: rgb(157, 160, 163);
      //   font-size: 12px;
      //   margin-right: 5px;
      //   margin-top: 5px;
      //   cursor: pointer;
      //   &:hover {
      //     opacity: 0.7;
      //     color: $theme;
      //   }
      // }
      .el-button--text {
        color: rgb(157, 160, 163);
        &:hover {
          opacity: 0.7;
          color: $theme;
        }
      }
    }
  }
}
</style>